// Copyright 2021
// Polus

import { StaticImage } from 'gatsby-plugin-image';
import * as React from 'react';

import Callout from '../../components/Callout';
import FeaturePageContainer from '../../components/features/FeaturePageContainer';
import ImageWithTextSection from '../../components/features/ImageWithTextSection';
import Layout from '../../components/Layout';
import Seo from '../../components/SEO';

const SpacedRepetition = (): React.ReactElement => (
  <Layout>
    <Seo title="Spaced Repetition" />
    <FeaturePageContainer>
      {/* TODO: make shared div */}
      <div className="flex flex-col items-center justify-around space-y-8 space-x-0 md:flex-row md:space-y-0 md:space-x-8">
        <div className="w-full max-w-[28rem]">
          <iframe
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            frameBorder="0"
            height="320"
            src="https://www.youtube.com/embed/NhTg3zckFvg"
            title="YouTube video player"
            width="100%"
            allowFullScreen
          />
        </div>
        <div className="md:w-1/2">
          <h1 className="mb-3 text-2xl font-bold md:text-4xl">Spaced Repetition</h1>
          <p>
            Spaced Repetition is the most powerful feature on Studius. Every time you study a card,
            Studius adjusts the optimal time the card will be studied again in the future.
          </p>
        </div>
      </div>

      <div>
        <h2 className="mb-3 text-xl font-bold md:text-2xl">What is Spaced Repetition?</h2>
        <div className="space-y-4">
          <p>
            Spaced repetition is a technique for efficient memorization and practice that's based on
            the principle that{' '}
            <b>the more you review something, the more likely you are to remember it</b>.
          </p>
          <p>The idea is simple:</p>
          <ol className="ml-4 list-decimal">
            <li className="mb-2">Start by reviewing the material to be learned. </li>
            <li className="mb-2">
              Review it again at increasingly longer intervals, spaced out over days, weeks, or even
              months.{' '}
            </li>
            <li className="mb-2">
              As you keep reviewing the material, you'll gradually increase the time intervals
              between reviews, until you can finally go months or even years without seeing the
              material again.
            </li>
          </ol>
          <p>
            The spaced repetition technique has been proven to be one of the most effective ways to
            learn and remember new information.
          </p>
          <p>
            <a
              className="font-bold text-blue-500 hover:underline"
              href="https://youtu.be/Z-zNHHpXoMM?t=63"
              rel="noreferrer"
              target="_blank"
            >
              Visual person? Watch Ali Abdaal's great explanation on YouTube.
            </a>
          </p>
        </div>
      </div>

      <ImageWithTextSection
        content="Every time you study a card, Studius adjusts the optimal time the card will be studied
            again in the future. It happens automatically, by default."
        header="Works by default"
        image={
          <StaticImage
            alt="Spaced repetition example in the Study mode"
            className="w-5/6"
            quality={100}
            src="../../images/study-mode-screenshot.png"
          />
        }
      />

      <ImageWithTextSection
        content="Studius collects statistics when you study. They are private and only available to you."
        header="Tracks your progress"
        image={
          <StaticImage
            alt="Spaced repetition example in the Study mode"
            className="w-5/6"
            quality={100}
            src="../../images/study-mode-stats-screenshot.png"
          />
        }
      />

      <ImageWithTextSection
        content="You can reset the spaced repetition history for every card in a deck. We all like to avoid it, but if need be you can reset cards to pull all-nighters or cram during exam season."
        header="Resets for cramming"
        image={
          <StaticImage
            alt="Spaced repetition reset example for a deck"
            quality={100}
            src="../../images/deck-mode-reset.png"
          />
        }
      />

      <ImageWithTextSection
        content="When you're too busy having fun not studying, Studius sends an email to remind you of
        the card that needs studying most."
        header="Reminds you when you forget"
        image={
          <StaticImage
            alt="Spaced repetition example in the Study mode"
            quality={100}
            src="../../images/email-reminder-screenshot.png"
          />
        }
      />

      <Callout label="Convinced? Use Studius to study faster and better with spaced repetition." />
    </FeaturePageContainer>

    {/* TODOs */}
    {/* [later] Upsell test mode */}
    {/* [later] Compare to Quizlet, Anki */}
    {/* [later] Add link to other features */}
  </Layout>
);

export default SpacedRepetition;
